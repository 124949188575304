.techstack {
    display: flex;
    justify-content: space-around;
}

.stack {
    display: grid;
}

.stack img {
    height: 55px;
    width: 55px;
    flex-shrink: 0;
    filter: none;
    filter: brightness(0.6);
    image-rendering: auto;
}

#javascript {
    height: 49px;
    width: 49px;
}

@media only screen and (min-width: 600px) {
    img {
        width: 40px;
    }
    img:hover {
        filter: brightness(1) !important;
    }
}