html body div#root main div#tsparticles.wrapper canvas.canvas{
  z-index: -2 !important;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

html {
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 600px) {
  html {
    width: 100%;
    overflow-x: hidden;
  }
}