#skills {
  width: 100px;
}

.parallax {
  color: white;
  font-size: 20px;
  width: 100%;
  opacity: 0.78;
  background-color: black;
  min-height: 100vh;
  image-rendering: pixelated;
  /* sharpens image
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-inline: 5%;
}

.text-box {
  opacity: 77%;
  background-color: black;
}

.projects-container {
  width: 45%;
}

.projects {
  font-family: "Roboto";
  text-align: center;
}

.projects-iframe-wrapper {
  height: 450px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .parallax {
    padding-inline: unset;
    flex-direction: column;
    justify-content: space-around;
  }
  .projects-container {
    width: 75%;
  }
}