.hero-card {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 999;
    padding-left: 1%;
    margin: 0;
    overflow: hidden
}

.name {
    font-size: 40px;
    font-weight: 600;
    color: #fdfdfe;
    font-family: Roboto;
    text-shadow: rgb(0 0 0) 0px 0px 5px;
    margin: 0;
    flex-grow: 0;
}

.job-title {
    font-size: 24px;
    font-weight: 600;
    color: #fdfdfe;
    text-shadow: rgb(0 0 0) 0px 0px 5px;
    font-family: Roboto;
}

H4 {
    background: linear-gradient(-45deg, #9d9d9d, #858585, #efefef, #efefef);
    background-size: 200%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    font-size: 10px;
    text-transform: uppercase;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: animated_text 10s ease-in-out infinite;
    -moz-animation: animated_text 10s ease-in-out infinite;
    -webkit-animation: animated_text 3s ease-in-out infinite;
    margin-bottom: 0px;
}

@keyframes animated_text {
    0% {
        background-position: 0px 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0px 50%;
    }
}

.arrow {
    border: solid rgb(255, 255, 255, 45%);
    border-width: 0px 8px 8px 0px;
    display: inline-block;
    padding: 10px;
    background: linear-gradient(-45deg, #9d9d9d, #858585, #efefef, #efefef);
    background-size: 200%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    font-size: 10px;
    text-transform: uppercase;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: animated_text 10s ease-in-out infinite;
    -moz-animation: animated_text 10s ease-in-out infinite;
    -webkit-animation: animated_text 3s ease-in-out infinite;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

#scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: opacity 0.7s;
}

#container {
    position: absolute;
    display: flex;
    bottom: 15px;
    width: 100%;
    justify-content: center;
}

#card {
    background: #0000005e;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    max-width: 338px;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.spacer {
    width: "100px";
}

.title {
    display: flex;
}

@media only screen and (max-width: 600px) {
    img {
        width: 40px;
    }
}