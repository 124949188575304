/* .hover {
    transition:ease-in;
    transition-duration: 500ms;
} */
.extlink:hover img {
    width:50px;
}
.extlinks {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;
}

.extlink {
    display: grid;
}

.extlink img {
    width: 40px;
    transition:ease-in;
    transition-duration: 100ms;
}